import { Currency, CurrencyAmount, ETHER, JSBI, Pair, Percent, Price, TokenAmount, Token } from '@gtoken/sdk'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PairState, usePair } from '../../data/Reserves'
import { useTotalSupply } from '../../data/TotalSupply'

import { useActiveWeb3React } from '../../hooks'
import { wrappedCurrency, wrappedCurrencyAmount } from '../../utils/wrappedCurrency'
import { AppDispatch, AppState } from '../index'
import { tryParseAmount } from '../swap/hooks'
import { useCurrencyBalances } from '../wallet/hooks'
import { typeInput } from './actions'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import { GTOKEN_MAP } from 'constants/gtokens/gtokens'
import { BigNumber } from 'ethers'
import { calculateFeeOnToken, ADD_LIQUIDITY_GAS_LIMIT } from '../../constants/price/price'
import { addLiquitityFeeInTokens } from '../../state/fee/actions'
import {BigNumber as JSBigNumber} from 'bignumber.js'
import { useAddLiquidityState } from 'state/AddLiquidity/hooks'
import { Field } from 'state/AddLiquidity/actions'
import { useCurrency } from '../../hooks/Tokens'

const ZERO = JSBI.BigInt(0)

export function useMintState(): AppState['mint'] {
  return useSelector<AppState, AppState['mint']>(state => state.mint)
}

export function useMintActionHandlers(
  noLiquidity: boolean | undefined
): {
  onFieldAInput: (typedValue: string) => void
  onFieldBInput: (typedValue: string) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onFieldAInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.CURRENCY_A, typedValue, noLiquidity: noLiquidity === true }))
    },
    [dispatch, noLiquidity]
  )
  const onFieldBInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.CURRENCY_B, typedValue, noLiquidity: noLiquidity === true }))
    },
    [dispatch, noLiquidity]
  )

  return {
    onFieldAInput,
    onFieldBInput
  }
}

export function useDerivedMintInfo(): {
  dependentField: Field
  currencies: { [field in Field]?: Currency }
  pair?: Pair | null
  pairState: PairState
  currencyBalances: { [field in Field]?: CurrencyAmount }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  parsedOriginalAmounts: { [field in Field]?: CurrencyAmount }
  price?: Price
  noLiquidity?: boolean
  liquidityMinted?: TokenAmount
  poolTokenPercentage?: Percent
  error?: string
} {
  const { account, chainId, library } = useActiveWeb3React()

  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    [Field.CURRENCY_A]: { currencyId: currencyIdA },
    [Field.CURRENCY_B]: { currencyId: currencyIdB },
  } = useAddLiquidityState()

  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  const dependentField = independentField === Field.CURRENCY_A ? Field.CURRENCY_B : Field.CURRENCY_A

  const wrappedTokenA = currencyA as WrappedTokenInfo
  const gTokenInfoA = wrappedTokenA === undefined || wrappedTokenA === null ? undefined : {
    chainId: wrappedTokenA.tokenInfo.chainId,
    address: GTOKEN_MAP[chainId!]!.get(wrappedTokenA.tokenInfo.address)!,
    name: 'Geode ' + wrappedTokenA.tokenInfo.name,
    decimals: wrappedTokenA.tokenInfo.decimals,
    symbol: 'g' + wrappedTokenA.tokenInfo.symbol,
    logoURI: wrappedTokenA.tokenInfo.logoURI!,
    tags: wrappedTokenA.tokenInfo.tags!
  }
  const gCurrencyA = gTokenInfoA === undefined ? undefined : new WrappedTokenInfo(gTokenInfoA, wrappedTokenA.tags)

  const wrappedTokenB = currencyB as WrappedTokenInfo
  const gTokenInfoB = wrappedTokenB === undefined || wrappedTokenB === null ? undefined : {
    chainId: wrappedTokenB.tokenInfo.chainId,
    address: GTOKEN_MAP[chainId!]!.get(wrappedTokenB.tokenInfo.address)!,
    name: 'Geode ' + wrappedTokenB.tokenInfo.name,
    decimals: wrappedTokenB.tokenInfo.decimals,
    symbol: 'g' + wrappedTokenB.tokenInfo.symbol,
    logoURI: wrappedTokenB.tokenInfo.logoURI!,
    tags: wrappedTokenB.tokenInfo.tags!
  }
  const gCurrencyB = gTokenInfoB === undefined ? undefined : new WrappedTokenInfo(gTokenInfoB, wrappedTokenB.tags)

  const gCurrencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.CURRENCY_A]: gCurrencyA ?? undefined,
      [Field.CURRENCY_B]: gCurrencyB ?? undefined
    }),
    [gCurrencyA, gCurrencyB]
  )

  // tokens
  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.CURRENCY_A]: currencyA ?? undefined,
      [Field.CURRENCY_B]: currencyB ?? undefined
    }),
    [currencyA, currencyB]
  )

  // pair
  const [pairState, pair] = usePair(currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B])
  const totalSupply = useTotalSupply(pair?.liquidityToken)

  const noLiquidity: boolean =
    pairState === PairState.NOT_EXISTS || Boolean(totalSupply && JSBI.equal(totalSupply.raw, ZERO))

  // balances
  const balances = useCurrencyBalances(account ?? undefined, [
    currencies[Field.CURRENCY_A],
    currencies[Field.CURRENCY_B]
  ])
  const currencyBalances: { [field in Field]?: CurrencyAmount } = {
    [Field.CURRENCY_A]: balances[0],
    [Field.CURRENCY_B]: balances[1]
  }

  // amounts
  const independentAmount: CurrencyAmount | undefined = tryParseAmount(typedValue, gCurrencies[independentField])
  const dependentAmount: CurrencyAmount | undefined = useMemo(() => {
    if (noLiquidity) {
      if (otherTypedValue && gCurrencies[dependentField]) {
        return tryParseAmount(otherTypedValue, gCurrencies[dependentField])
      }
      return undefined
    } else if (independentAmount) {
      // we wrap the currencies just to get the price in terms of the other token
      const wrappedIndependentAmount = wrappedCurrencyAmount(independentAmount, chainId)
      const [tokenA, tokenB] = [wrappedCurrency(gCurrencyA, chainId), wrappedCurrency(gCurrencyB, chainId)]
      if (tokenA && tokenB && wrappedIndependentAmount && pair) {
        const dependentCurrency = dependentField === Field.CURRENCY_B ? gCurrencyB : gCurrencyA
        const dependentTokenAmount =
          dependentField === Field.CURRENCY_B
            ? pair.priceOf(tokenA).quote(wrappedIndependentAmount)
            : pair.priceOf(tokenB).quote(wrappedIndependentAmount)
        return dependentCurrency === ETHER ? CurrencyAmount.ether(dependentTokenAmount.raw) : dependentTokenAmount
      }
      return undefined
    } else {
      return undefined
    }
  }, [noLiquidity, otherTypedValue, dependentField, independentAmount, chainId, pair, gCurrencyB, gCurrencyA, gCurrencies])
  
  const parsedAmounts: { [field in Field]: CurrencyAmount | undefined } = {
    [Field.CURRENCY_A]: independentField === Field.CURRENCY_A ? independentAmount : dependentAmount,
    [Field.CURRENCY_B]: independentField === Field.CURRENCY_A ? dependentAmount : independentAmount
  }

  const independentOriginalAmount: CurrencyAmount | undefined = tryParseAmount(typedValue, currencies[independentField])
  const dependentTokenAmount = dependentAmount as TokenAmount
  const dependentToken = currencies[dependentField] as Token
  const dependentOriginalAmount: CurrencyAmount | undefined = dependentTokenAmount === undefined || dependentToken === undefined ? undefined : new TokenAmount(dependentToken, dependentTokenAmount.raw)

  const parsedOriginalAmounts: { [field in Field]: CurrencyAmount | undefined } = {
    [Field.CURRENCY_A]: independentField === Field.CURRENCY_A ? independentOriginalAmount : dependentOriginalAmount,
    [Field.CURRENCY_B]: independentField === Field.CURRENCY_A ? dependentOriginalAmount : independentOriginalAmount
  }

  const price = useMemo(() => {
    if (noLiquidity) {
      const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts
      if (currencyAAmount && currencyBAmount) {
        return new Price(currencyAAmount.currency, currencyBAmount.currency, currencyAAmount.raw, currencyBAmount.raw)
      }
      return undefined
    } else {
      const wrappedCurrencyA = wrappedCurrency(gCurrencyA, chainId)
      return pair && wrappedCurrencyA ? pair.priceOf(wrappedCurrencyA) : undefined
    }
  }, [chainId, noLiquidity, pair, parsedAmounts, gCurrencyA])

  // liquidity minted
  const liquidityMinted = useMemo(() => {
    const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts
    const [tokenAmountA, tokenAmountB] = [
      wrappedCurrencyAmount(currencyAAmount, chainId),
      wrappedCurrencyAmount(currencyBAmount, chainId)
    ]
    if (pair && totalSupply && tokenAmountA && tokenAmountB) {
      return pair.getLiquidityMinted(totalSupply, tokenAmountA, tokenAmountB)
    } else {
      return undefined
    }
  }, [parsedAmounts, chainId, pair, totalSupply])

  const poolTokenPercentage = useMemo(() => {
    if (liquidityMinted && totalSupply) {
      return new Percent(liquidityMinted.raw, totalSupply.add(liquidityMinted).raw)
    } else {
      return undefined
    }
  }, [liquidityMinted, totalSupply])

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }

  if (pairState === PairState.INVALID) {
    error = error ?? 'Invalid pair'
  }

  if (!parsedAmounts[Field.CURRENCY_A] || !parsedAmounts[Field.CURRENCY_B]) {
    error = error ?? 'Enter an amount'
  }

  const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts

  const addLiquitityFeeStr = useSelector<AppState, AppState['fee']['addLiquitityFeeInTokens']>(state => state.fee.addLiquitityFeeInTokens)
  const addLiquitityFee = addLiquitityFeeStr === undefined ? undefined : 
    currencies[Field.CURRENCY_A] === undefined ? undefined : new JSBigNumber(addLiquitityFeeStr).div(new JSBigNumber(10).pow(currencies[Field.CURRENCY_A]!.decimals).toString())
  
  if (currencyAAmount && currencyBalances && currencyBalances?.[Field.CURRENCY_A]) {
    if (currencyBalances?.[Field.CURRENCY_A]?.lessThan(currencyAAmount)) {
      error = 'Insufficient ' + currencies[Field.CURRENCY_A]?.symbol + ' balance'
    } else if (addLiquitityFee !== undefined && addLiquitityFee.plus(new JSBigNumber(currencyAAmount.toSignificant(currencyAAmount.currency.decimals))).gt(new JSBigNumber(currencyBalances?.[Field.CURRENCY_A]!.toSignificant(currencyBalances?.[Field.CURRENCY_A]?.currency.decimals)))) {
      error = 'Insufficient ' + currencies[Field.CURRENCY_A]?.symbol + ' balance for gas fee'
    }
  }

  if (currencyBAmount && currencyBalances?.[Field.CURRENCY_B]?.lessThan(currencyBAmount)) {
    error = 'Insufficient ' + currencies[Field.CURRENCY_B]?.symbol + ' balance'
  }

  const dispatch = useDispatch<AppDispatch>()
  library?.getGasPrice().then((value: BigNumber) => {
    if (chainId !== undefined &&  wrappedTokenA && value !== undefined) {
      calculateFeeOnToken(chainId, wrappedTokenA.tokenInfo.address, wrappedTokenA!.decimals, value.mul(ADD_LIQUIDITY_GAS_LIMIT)).then((fee) => {
        dispatch(addLiquitityFeeInTokens(fee.toString()))
      })
    }
  })

  return {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    parsedOriginalAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error
  }
}
