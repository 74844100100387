import React from 'react'
import { Price } from '@gtoken/sdk'
import { useContext } from 'react'
import { Repeat } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { StyledBalanceMaxMini } from './styleds'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const theme = useContext(ThemeContext)

  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const originalQuoteTokenSymbol = price?.quoteCurrency?.symbol !== undefined && price?.quoteCurrency?.symbol.startsWith('g') ? 
    price?.quoteCurrency?.symbol.substring(1) : price?.quoteCurrency?.symbol
  const originalBaseTokenSymbol = price?.baseCurrency?.symbol !== undefined && price?.baseCurrency?.symbol.startsWith('g') ? 
  price?.baseCurrency?.symbol.substring(1) : price?.baseCurrency?.symbol
  const label = showInverted
    ? `${originalQuoteTokenSymbol} per ${originalBaseTokenSymbol}`
    : `${originalBaseTokenSymbol} per ${originalQuoteTokenSymbol}`

  return (
    <Text
      fontWeight={500}
      fontSize={14}
      color={theme.text2}
      style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
    >
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <Repeat size={14} />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
