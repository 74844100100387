import { Currency } from '@gtoken/sdk'
import React, { useContext } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'
import { BigNumber as JSBigNumber } from 'bignumber.js'

export function GasFeeBar({
  currencies,
  gasFee
}: {
  currencies: { [field in Field]?: Currency }
  gasFee?: JSBigNumber
}) {
  const theme = useContext(ThemeContext)
  return (
    <AutoColumn gap="md">
      <AutoRow justify="space-around" gap="4px">
        <AutoColumn justify="center">
          <TYPE.black>{gasFee ? `${gasFee.toPrecision(6)}` : '-'}</TYPE.black>
          <Text fontWeight={500} fontSize={14} color={theme.text2} pt={1}>
            {currencies[Field.CURRENCY_A]?.symbol}
          </Text>
        </AutoColumn>
      </AutoRow>
    </AutoColumn>
  )
}
