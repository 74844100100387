import { createReducer } from '@reduxjs/toolkit'
import { Field, selectCurrency } from './actions'

export interface AddLiquidityState {
  readonly [Field.CURRENCY_A]: {
    readonly currencyId: string | undefined
  }
  readonly [Field.CURRENCY_B]: {
    readonly currencyId: string | undefined
  }
}

const initialState: AddLiquidityState = {
  [Field.CURRENCY_A]: {
    currencyId: ''
  },
  [Field.CURRENCY_B]: {
    currencyId: ''
  }
}

export default createReducer<AddLiquidityState>(initialState, builder =>
  builder
    .addCase(selectCurrency, (state, { payload: { currencyId, field } }) => {
      const otherField = field === Field.CURRENCY_A ? Field.CURRENCY_B : Field.CURRENCY_A
      if (currencyId === state[otherField].currencyId) {
        // the case where we have to swap the order
        return {
          ...state,
          [field]: { currencyId: currencyId },
          [otherField]: { currencyId: state[field].currencyId }
        }
      } else {
        // the normal case
        return {
          ...state,
          [field]: { currencyId: currencyId }
        }
      }
    })
)
