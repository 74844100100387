import { createReducer } from "@reduxjs/toolkit";
import { originalTokens } from './actions'
import { Token } from '@gtoken/sdk'

export interface AssetStates {
    readonly originalTokens : Token[]
}

const initState : AssetStates = {
    originalTokens: []
}

export default createReducer(initState, builder => 
  builder
    .addCase(originalTokens, (state, { payload: originalTokens}) => {
        state.originalTokens = originalTokens
    })
)