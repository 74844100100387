import { createReducer } from "@reduxjs/toolkit";
import { addLiquitityFeeInTokens, swapFeeInTokens } from './actions'

export interface FeeStates {
    readonly addLiquitityFeeInTokens : string | undefined
    readonly swapFeeInTokens : string | undefined
}

const initState : FeeStates = {
    addLiquitityFeeInTokens: undefined,
    swapFeeInTokens: undefined
}

export default createReducer(initState, builder => 
    builder
      .addCase(addLiquitityFeeInTokens, (state, { payload: fee}) => {
          state.addLiquitityFeeInTokens = fee
      })
      .addCase(swapFeeInTokens, (state, { payload: fee}) => {
          state.swapFeeInTokens = fee
      })
  )