import { ChainId } from "@gtoken/sdk";

export const RELAYER_ENDPOINT_MAP: { [environment: string] : { [chainId in ChainId]?: string } } = {
    "production": {
        [ChainId.BSCTEST]: 'https://gtoken-geode-staging.ata.network:3390',
        [ChainId.BSCMAIN]: 'https://gtoken-geode-production.ata.network:3390',
        [ChainId.POLYGON]: 'https://gtoken-geode-production.ata.network:3390',
        [ChainId.MAINNET]: 'https://gtoken-geode-production.ata.network:3390',
    },
    "staging": {
        [ChainId.BSCTEST]: 'https://gtoken-geode-staging.ata.network:3390',
        [ChainId.BSCMAIN]: 'https://gtoken-geode-staging.ata.network:3390',
        [ChainId.POLYGON]: 'https://gtoken-geode-staging.ata.network:3390',
        [ChainId.MAINNET]: 'https://gtoken-geode-staging.ata.network:3390',
    }
}