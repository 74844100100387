import { TokenAmount, Pair, Currency, Token } from '@gtoken/sdk'
import { useMemo } from 'react'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { Interface } from '@ethersproject/abi'
import { useActiveWeb3React } from '../hooks'

import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { GTOKEN_MAP } from '../constants/gtokens/gtokens'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        if (tokenA && tokenB 
            && tokenA.address !== undefined && tokenB.address !== undefined) {
          const gTokenA = tokenA.symbol?.startsWith('g') ? tokenA : new Token(tokenA?.chainId, GTOKEN_MAP[chainId!]!.get(tokenA.address)!, tokenA?.decimals, 'g' + tokenA?.symbol, 'Geode ' + tokenA?.name)
          const gTokenB = tokenB.symbol?.startsWith('g') ? tokenB : new Token(tokenB?.chainId, GTOKEN_MAP[chainId!]!.get(tokenB.address)!, tokenB?.decimals, 'g' + tokenB?.symbol, 'Geode ' + tokenB?.name)
          if (gTokenA.address === undefined || gTokenB.address === undefined) {
            return undefined
          }
          return !gTokenA.equals(gTokenB) ? Pair.getAddress(gTokenA, gTokenB) : undefined
        } else {
          return undefined
        }
      }),
    [tokens, chainId]
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      
      const gTokenA = tokenA.symbol?.startsWith('g') ? tokenA : new Token(tokenA?.chainId, GTOKEN_MAP[chainId!]!.get(tokenA.address)!, tokenA?.decimals, 'g' + tokenA?.symbol, 'Geode ' + tokenA?.name)
      const gTokenB = tokenB.symbol?.startsWith('g') ? tokenB : new Token(tokenB?.chainId, GTOKEN_MAP[chainId!]!.get(tokenB.address)!, tokenB?.decimals, 'g' + tokenB?.symbol, 'Geode ' + tokenB?.name)
      
      const [token0, token1] = gTokenA.sortsBefore(gTokenB) ? [gTokenA, gTokenB] : [gTokenB, gTokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
    })
  }, [results, tokens, chainId])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]])[0]
}
