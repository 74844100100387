import { useAllTokens } from "hooks/Tokens"
import useInterval from "hooks/useInterval"
import { useCallback } from "react"
import { useGTokenContract } from 'hooks/useContract'
import { useSingleCallResult } from 'state/multicall/hooks'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { Token } from '@gtoken/sdk'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../index'
import { Dispatch } from 'redux'
import { originalTokens } from './actions'

export function GetUnwrappedToken(gToken: WrappedTokenInfo): WrappedTokenInfo {

    const contract = useGTokenContract(gToken.address)
    const result = useSingleCallResult(contract, 'asset', []).result
    const originalTokenAddress = result ? result.toString() : undefined
    const tokenInfo = {
        chainId: gToken.tokenInfo.chainId,
        address: originalTokenAddress!,
        name: gToken.tokenInfo.name.substring(6),
        decimals: gToken.tokenInfo.decimals,
        symbol: gToken.tokenInfo.symbol!.substring(1),
        logoURI: gToken.tokenInfo.logoURI!
    }

    return new WrappedTokenInfo(tokenInfo, [])
}

export async function GetUnwrappedTokens(gTokens: Token[], dispatch: Dispatch) {
    let originals: Token[] = []
    for (let token of gTokens) {
        const gToken = token as WrappedTokenInfo 
        if (gToken.name?.startsWith('Geode')) {
            originals.push(GetUnwrappedToken(gToken))
        }
    }
    dispatch(originalTokens(originals))
}

export default function Updater(): null {
    const dispatch = useDispatch<AppDispatch>()
    const gTokens = Object.values(useAllTokens())

    const getUnwrappedToken = useCallback(() => GetUnwrappedTokens(gTokens, dispatch), [gTokens, dispatch])
    // getUnwrappedToken()
    useInterval(getUnwrappedToken, 1000 * 60 * 60)
    return null
}