import { BigNumber } from "@ethersproject/bignumber";
import { ChainId } from "@gtoken/sdk";
import { BigNumber as JSBigNumber } from "bignumber.js";

export const PRICE_API_PREFIX: { [chainId in ChainId]?: string} = {
    [ChainId.BSCTEST]: 'https://api.coingecko.com/api/v3/simple/token_price/binance-smart-chain?',
    [ChainId.BSCMAIN]: 'https://api.coingecko.com/api/v3/simple/token_price/binance-smart-chain?',
    [ChainId.POLYGON]: 'https://api.coingecko.com/api/v3/simple/token_price/polygon-pos?',
    [ChainId.MAINNET]: 'https://api.coingecko.com/api/v3/simple/token_price/ethereum?'
}

export const LIQUIDITY_EARN_PERCENT: { [chainId in ChainId]?: string} = {
    [ChainId.BSCTEST]: '0.17',
    [ChainId.BSCMAIN]: '0.17',
    [ChainId.POLYGON]: '0.25',
    [ChainId.MAINNET]: '0.3'
}

export const LIQUIDITY_PROVIDER_FEE: { [chainId in ChainId]?: string} = {
    [ChainId.BSCTEST]: '0.25',
    [ChainId.BSCMAIN]: '0.25',
    [ChainId.POLYGON]: '0.3',
    [ChainId.MAINNET]: '0.3'
}

export const SWAP_GAS_LIMIT = 410000
export const ADD_LIQUIDITY_GAS_LIMIT = 530000
export const CREATE_PAIR_GAS_LIMIT = 4100000
export const HOP_ADDITIONAL_GAS = 140000

export async function calculateFeeOnToken(chainId: ChainId | undefined, address: string | undefined, decimals: number, nativeTokenAmount: BigNumber | undefined): Promise<JSBigNumber> {
    if (chainId === undefined) {
        throw Error("Please connect to networks")
    }
    if (nativeTokenAmount === undefined) {
        throw Error("Fee on native token unknown")
    }
    if (address === undefined) {
        throw Error("Token address unknown")
    }
    
    if (chainId === ChainId.BSCTEST) {
        return new JSBigNumber(0.1).multipliedBy(new JSBigNumber(10).pow(decimals))
    } else if (chainId === ChainId.BSCMAIN) {
        return await calculateFee(chainId, address, decimals, nativeTokenAmount, 'bnb', 18)
    } else if (chainId === ChainId.POLYGON) { 
        return await calculateFeeOnPolygon(chainId, address, decimals, nativeTokenAmount)
    } else if (chainId === ChainId.MAINNET) {
        return await calculateFee(chainId, address, decimals, nativeTokenAmount, 'eth', 18)
    } else {
        throw Error("Unsupported Network")
    }
}

async function calculateFee(chainId: ChainId, address: string, decimals: number, nativeTokenAmount: BigNumber, baseCurrency: string, baseCurrencyDecimal: number): Promise<JSBigNumber> {
    const priceApiPrefix = PRICE_API_PREFIX[chainId]
    if (priceApiPrefix === undefined) {
        throw Error("Unable to calculate fee")
    }

    const response = await fetch(priceApiPrefix + 'contract_addresses=' + address + '&vs_currencies=' + baseCurrency)
    const responseMap = await response.json()
    const data = responseMap[address.toLowerCase()]
    var baseRatio
    if (baseCurrency === 'bnb') {
        const { bnb } = data
        baseRatio = bnb
    } else if (baseCurrency === 'eth') {
        const { eth } = data
        baseRatio = eth
    }
    const price = new JSBigNumber(baseRatio).multipliedBy(new JSBigNumber(10).pow(baseCurrencyDecimal)).div(new JSBigNumber(10).pow(decimals))
    // const priceBNB = parseFloat(price_BNB) * Math.pow(10, 18) / Math.pow(10, decimals)
    const feeInToken = new JSBigNumber(nativeTokenAmount.toString()).div(price)
    return feeInToken
}

async function calculateFeeOnPolygon(chainId: ChainId, address: string, decimals: number, nativeTokenAmount: BigNumber): Promise<JSBigNumber> {
    const priceApiPrefix = PRICE_API_PREFIX[chainId]
    if (priceApiPrefix === undefined) {
        throw Error("Unable to calculate fee")
    }

    const response = await fetch(priceApiPrefix + 'contract_addresses=' + address + '&vs_currencies=bnb')
    const responseMap = await response.json()
    const data = responseMap[address.toLowerCase()]
    const { bnb } = data
    const priceBNB = new JSBigNumber(bnb).multipliedBy(new JSBigNumber(10).pow(18)).div(new JSBigNumber(10).pow(decimals))
    
    const maticBnbRatioApi = 'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=bnb'
    const maticResponse = await fetch(maticBnbRatioApi)
    const maticResponseMap = await maticResponse.json()
    const maticData = maticResponseMap['matic-network']
    const maticBnb = maticData['bnb']
    const maticBnbPrice = new JSBigNumber(maticBnb)

    // const priceBNB = parseFloat(price_BNB) * Math.pow(10, 18) / Math.pow(10, decimals)
    const feeInToken = new JSBigNumber(nativeTokenAmount.toString()).div(priceBNB.div(maticBnbPrice))
    return feeInToken
}